import { GTM_CONFIG, GTM_EVENTS } from '../constants/analytics.constants';

/**
 * Service for handling Google Tag Manager operations
 */
export class GTMService {
  /**
   * @param {import('vue-router').Router} router - Vue Router instance
   */
  constructor(router) {
    this.GTM_ID = GTM_CONFIG.ID;
    this.router = router;
    this.initializeGTM();
    this.trackPageViews();
  }

  /**
   * Initialize Google Tag Manager
   */
  initializeGTM() {
    try {
      if (!this.GTM_ID) {
        console.error('GTM ID is not defined');
        return;
      }

      // Initialize dataLayer
      window.dataLayer = window.dataLayer || [];

      if (process.env.NODE_ENV === 'development') {
        console.log('GTM initialized in development mode');
      }

      // GTM script
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${this.GTM_ID}');
      `;
      document.head.appendChild(script);

      // GTM noscript iframe
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.GTM_ID}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noscript.appendChild(iframe);
      document.body.insertBefore(noscript, document.body.firstChild);
    } catch (error) {
      console.error('Error initializing GTM:', error);
    }
  }

  /**
   * Track page views
   */
  trackPageViews() {
    this.router.afterEach((to) => {
      try {
        this.pushEvent(GTM_EVENTS.PAGE_VIEW, {
          page_path: to.fullPath,
          page_title: document.title
        });
      } catch (error) {
        console.error('Error tracking page view:', error);
      }
    });
  }

  /**
   * Push event to dataLayer
   * @param {string} eventName - Name of the event
   * @param {Object} eventData - Event data
   */
  pushEvent(eventName, eventData = {}) {
    try {
      if (!window.dataLayer) {
        console.warn('GTM dataLayer not initialized');
        return;
      }

      window.dataLayer.push({
        event: eventName,
        ...eventData,
        timestamp: new Date().toISOString(),
        environment: process.env.NODE_ENV
      });

      if (process.env.NODE_ENV === 'development') {
        console.log('GTM Event pushed:', { event: eventName, ...eventData });
      }
    } catch (error) {
      console.error(`Error pushing GTM event ${eventName}:`, error);
    }
  }
}

/**
 * Vue plugin for GTM
 */
export const gtmPlugin = {
  /**
   * @param {import('vue').App} app - Vue application instance
   * @param {import('vue-router').Router} router - Vue Router instance
   */
  install(app, router) {
    const gtmService = new GTMService(router);
    app.config.globalProperties.$gtm = gtmService;
  }
}; 