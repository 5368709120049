import {createApp} from 'vue';
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';
import VueCookies from 'vue3-cookies';
import { createPinia } from 'pinia';
import '@mdi/font/css/materialdesignicons.css';
import 'swiper/css/bundle';
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import App from './App.vue';
import router from './router';
import { useAuthStore } from "./stores/auth";
import { gtmPlugin } from './services/gtm';

const vuetify = createVuetify({
  components,
  directives,
});
const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(VueCookies);
app.use(gtmPlugin, router);

const authStore = useAuthStore();
// Check authentication state on app initialization
authStore.checkAuth().catch(err => {
    console.error(err);
    authStore.logout();
});

app.use(Vue3Toastify, {
    autoClose: 10000,
    position: 'top-right',
    theme: 'light',
});

app.use(vuetify).mount('#app');
