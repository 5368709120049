/**
 * @typedef {Object} GTMEvents
 * @property {string} PAGE_VIEW - Page view event name
 * @property {string} CTA_CLICK - CTA click event name
 * @property {string} VIDEO_INTERACTION - Video interaction event name
 * @property {string} FAQ_INTERACTION - FAQ interaction event name
 * @property {string} SECTION_ADDED - Section added event name
 * @property {string} SOCIAL_MEDIA_CLICK - Social media click event name
 */

/**
 * GTM event name constants
 * @type {GTMEvents}
 */
export const GTM_EVENTS = {
  PAGE_VIEW: 'page_view',
  CTA_CLICK: 'cta_click',
  VIDEO_INTERACTION: 'video_interaction',
  FAQ_INTERACTION: 'faq_interaction',
  SECTION_ADDED: 'section_added',
  SOCIAL_MEDIA_CLICK: 'social_media_click'
};

/**
 * GTM configuration constants
 */
export const GTM_CONFIG = {
  ID: 'GTM-594XGW42',
  ENVIRONMENT: {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production'
  }
};

/**
 * Clarity configuration constants
 */
export const CLARITY_CONFIG = {
  PROJECT_ID: 'q7n575p2v2',
  ENVIRONMENT: {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production'
  }
}; 